import React, { useEffect } from 'react';
import { Container, Typography, Grid, Button, Box } from '@mui/material';
import HeroSection from '../components/_home/HeroSection';
import WhatsAppChatBot from '../components/_home/WhatsAppChatBot';
import ImageGallery from '../components/_home/ImageGallery';
import VideoSection from '../components/_home/VideoSection';
import InfographicsSection from '../components/_home/InfographicsSection';
import Services from './Services';

function Home() {
  useEffect(()=>{
    window.scrollTo(0, 0);
  },[])

  return (
    <Container>
      <HeroSection />

      <Services/>

      <section style={{ padding: '60px 0', backgroundColor: '#f4f4f4', marginTop: '60px' }}>
        <Typography variant="h3" gutterBottom align="center" sx={{ color: '#388E3C' }}>
          Our Work
        </Typography>
        <ImageGallery />
      </section>

      <section style={{ padding: '60px 0' }}>
        <Typography variant="h3" gutterBottom align="center" sx={{ color: '#388E3C' }}>
          Watch Our Story
        </Typography>
        <VideoSection />
      </section>

      <section style={{ padding: '60px 0', backgroundColor: '#f4f4f4' }}>
        <Typography variant="h3" gutterBottom align="center" sx={{ color: '#388E3C' }}>
          Infographics
        </Typography>
        <InfographicsSection />
      </section>

      <WhatsAppChatBot />
    </Container>
  );
}

export default Home;
