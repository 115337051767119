import React from 'react';
import { Container, Typography, Link, Box, IconButton, Grid } from '@mui/material';
import { Facebook, LinkedIn, Instagram, GitHub } from '@mui/icons-material';
import XIcon from '@mui/icons-material/X';

function Footer() {
  return (
    <Box
      sx={{
        backgroundColor: '#000000',
        color: 'white',
        padding: '40px 0',
        marginTop: '60px',
        textAlign: 'center',
      }}
    >
      <Container maxWidth="lg">
        <Grid container spacing={4} justifyContent="center">
          <Grid item xs={12} sm={4}>
            <Box sx={{ marginBottom: '40px' }}>
              <Typography variant="h4" sx={{ fontWeight: 'bold', marginBottom: '20px', color: '#ecf0f1' }}>
              TechInfoHub
              </Typography>
              <Typography variant="body1" paragraph sx={{ color: '#bdc3c7' }}>
                We provide cutting-edge IT solutions that help businesses thrive. Our expertise spans software development,
                web services, mobile apps, and IT consulting to deliver the best for your needs.
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={12} sm={4}>
            <Box sx={{ marginBottom: '40px' }}>
              <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '20px', color: '#ecf0f1' }}>
                Useful Links
              </Typography>
              <Link href="/services" color="inherit" sx={{ display: 'block', marginBottom: '10px', color: '#bdc3c7', fontWeight: 'bold' }}>
                Services
              </Link>
              <Link href="/blog" color="inherit" sx={{ display: 'block', marginBottom: '10px', color: '#bdc3c7', fontWeight: 'bold' }}>
                Blog
              </Link>
              <Link href="/contact" color="inherit" sx={{ display: 'block', marginBottom: '10px', color: '#bdc3c7', fontWeight: 'bold' }}>
                Contact Us
              </Link>
              <Link href="/aboutus" color="inherit" sx={{ display: 'block', marginBottom: '10px', color: '#bdc3c7', fontWeight: 'bold' }}>
                About Us
              </Link>
            </Box>
          </Grid>

          <Grid item xs={12} sm={4}>
            <Box sx={{ marginBottom: '40px' }}>
              <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '20px', color: '#ecf0f1' }}>
                Legal
              </Typography>
              <Link href="/privacy-policy" color="inherit" sx={{ display: 'block', marginBottom: '10px', color: '#bdc3c7', fontWeight: 'bold' }}>
                Privacy Policy
              </Link>
              <Link href="/terms-of-service" color="inherit" sx={{ display: 'block', marginBottom: '10px', color: '#bdc3c7', fontWeight: 'bold' }}>
                Terms of Service
              </Link>
              <Link href="/cookie-policy" color="inherit" sx={{ display: 'block', marginBottom: '10px', color: '#bdc3c7', fontWeight: 'bold' }}>
                Cookie Policy
              </Link>
            </Box>
          </Grid>

          <Grid item xs={12} sm={4}>
            <Box sx={{ marginBottom: '40px' }}>
              <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '20px', color: '#ecf0f1' }}>
                Connect with Us
              </Typography>
              <Box sx={{ display: 'flex', justifyContent: 'center', gap: 3 }}>
                <IconButton
                  color="inherit"
                  href="https://www.facebook.com"
                  target="_blank"
                  rel="noopener noreferrer"
                  sx={{
                    '&:hover': {
                      transform: 'scale(1.2)',
                      transition: 'all 0.3s ease',
                      color: '#3b5998',
                    },
                  }}
                >
                  <Facebook />
                </IconButton>
                <IconButton
                  color="inherit"
                  href="https://www.twitter.com"
                  target="_blank"
                  rel="noopener noreferrer"
                  sx={{
                    '&:hover': {
                      transform: 'scale(1.2)',
                      transition: 'all 0.3s ease',
                      color: '#1da1f2',
                    },
                  }}
                >
                  <XIcon />
                </IconButton>
                <IconButton
                  color="inherit"
                  href="https://www.linkedin.com"
                  target="_blank"
                  rel="noopener noreferrer"
                  sx={{
                    '&:hover': {
                      transform: 'scale(1.2)',
                      transition: 'all 0.3s ease',
                      color: '#0077b5',
                    },
                  }}
                >
                  <LinkedIn />
                </IconButton>
                <IconButton
                  color="inherit"
                  href="https://www.instagram.com"
                  target="_blank"
                  rel="noopener noreferrer"
                  sx={{
                    '&:hover': {
                      transform: 'scale(1.2)',
                      transition: 'all 0.3s ease',
                      color: '#e4405f',
                    },
                  }}
                >
                  <Instagram />
                </IconButton>
                <IconButton
                  color="inherit"
                  href="https://www.github.com"
                  target="_blank"
                  rel="noopener noreferrer"
                  sx={{
                    '&:hover': {
                      transform: 'scale(1.2)',
                      transition: 'all 0.3s ease',
                      color: '#333',
                    },
                  }}
                >
                  <GitHub />
                </IconButton>
              </Box>
            </Box>
          </Grid>

          {/* Address Section */}
          <Grid item xs={12}>
            <Box sx={{ marginBottom: '40px' }}>
              <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '20px', color: '#ecf0f1' }}>
                Our Address
              </Typography>
              <Typography variant="body1" sx={{ color: '#bdc3c7' }}>
              TechInfoHub<br />
                1234 Tech Street<br />
                Silicon Valley, CA 95000<br />
                United States
              </Typography>
            </Box>
          </Grid>
        </Grid>

        <Box sx={{ marginTop: '40px', textAlign: 'center' }}>
          <Typography variant="body2" color="inherit">
            © {new Date().getFullYear()} TechInfoHub.com. All rights reserved.
          </Typography>
        </Box>
      </Container>
    </Box>
  );
}

export default Footer;
