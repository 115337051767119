import React, { useEffect } from 'react';
import { Container, Paper, Typography, Box } from '@mui/material';

const CookiePolicy = () => {
  useEffect(()=>{
    window.scrollTo(0, 0);
  },[])

  return (
    <Container maxWidth="lg" sx={{ marginTop: 6 }}>
      <Paper sx={{ padding: 4 }}>
        <Typography variant="h4" fontWeight={600}  gutterBottom align="center">
          Cookie Policy
        </Typography>
        <Typography variant="body2" gutterBottom align="center">
          Last updated: November 2024
        </Typography>

        <section>
          <Typography variant="h6" sx={{ fontWeight: 'bold', marginTop: 3 }}>
            Introduction
          </Typography>
          <Typography variant="body1" sx={{ marginTop: 2 }}>
            This Cookie Policy explains how TechInfoHub uses cookies and similar technologies to enhance your experience
            when visiting our website. By using our website, you consent to the use of cookies in accordance with this policy.
          </Typography>
        </section>

        <section>
          <Typography variant="h6" sx={{ fontWeight: 'bold', marginTop: 3 }}>
            What Are Cookies?
          </Typography>
          <Typography variant="body1" sx={{ marginTop: 2 }}>
            Cookies are small text files stored on your device that help us collect and store information about your usage
            of our website, such as preferences and browsing habits.
          </Typography>
        </section>

        <section>
          <Typography variant="h6" sx={{ fontWeight: 'bold', marginTop: 3 }}>
            Types of Cookies We Use
          </Typography>
          <ul>
            <li><strong>Essential Cookies:</strong> Necessary for the operation of our website and services.</li>
            <li><strong>Performance Cookies:</strong> Collect data on how you use our website to improve our services.</li>
            <li><strong>Functional Cookies:</strong> Help us remember your preferences and enhance your user experience.</li>
            <li><strong>Advertising Cookies:</strong> Used to display relevant ads and track the effectiveness of our marketing campaigns.</li>
          </ul>
        </section>

        <section>
          <Typography variant="h6" sx={{ fontWeight: 'bold', marginTop: 3 }}>
            Managing Cookies
          </Typography>
          <Typography variant="body1" sx={{ marginTop: 2 }}>
            You can manage cookies through your browser settings. Most browsers allow you to block or delete cookies, but doing
            so may affect the functionality of certain parts of our website.
          </Typography>
        </section>

        <section>
          <Typography variant="h6" sx={{ fontWeight: 'bold', marginTop: 3 }}>
            Changes to This Policy
          </Typography>
          <Typography variant="body1" sx={{ marginTop: 2 }}>
            We may update this Cookie Policy from time to time. Any changes will be posted on this page, and the updated date
            will be revised. Please check this page regularly for updates.
          </Typography>
        </section>

        <section>
          <Typography variant="h6" sx={{ fontWeight: 'bold', marginTop: 3 }}>
            Contact Us
          </Typography>
          <Typography variant="body1" sx={{ marginTop: 2 }}>
            If you have any questions regarding this Cookie Policy, please contact us at: <a href="mailto:support@techinfohub.online">TechInfoHub</a>
          </Typography>
        </section>
      </Paper>
    </Container>
  );
};

export default CookiePolicy;
