import React, { useEffect } from 'react';
import { Container, Grid, Paper, Typography, Button, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';  // Import useNavigate for routing
import productImg1 from '../images/product-cms.jpg';
import productImg2 from '../images/product-2.avif';
import productImg3 from '../images/product-3.jpg';

const Products = () => {
  const navigate = useNavigate(); // Initialize useNavigate hook

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const productList = [
    {
      id: 1,
      name: 'Product A',
      description: 'This is a high-performance product designed to solve X problem.',
      features: ['Feature 1', 'Feature 2', 'Feature 3'],
      price: '$199',
      imgUrl: productImg1,
    },
    {
      id: 2,
      name: 'Product B',
      description: 'An innovative solution for Y need with amazing capabilities.',
      features: ['Feature A', 'Feature B', 'Feature C'],
      price: '$299',
      imgUrl: productImg2,
    },
    {
      id: 3,
      name: 'Product C',
      description: 'A premium product that combines efficiency and ease of use.',
      features: ['Feature X', 'Feature Y', 'Feature Z'],
      price: '$399',
      imgUrl: productImg3,
    },
  ];

  // Function to navigate to the Contact Us page
  const goToContact = () => {
    navigate('/contact'); // Navigate to the contact page
  };

  return (
    <Container maxWidth="lg" sx={{ marginTop: 6 }}>
      <Box sx={{ marginBottom: 6 }}>
        <Typography variant="h4" sx={{ fontWeight: '600', textAlign: 'center' }} gutterBottom>
          Our product categories
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={4}>
            <Paper
              sx={{
                padding: 3,
                textAlign: 'center',
                transition: 'all 0.3s ease',
                '&:hover': {
                  transform: 'scale(1.05)',
                  boxShadow: '0 4px 15px rgba(0, 0, 0, 0.1)',
                },
              }}
            >
              <Typography variant="h6">Software Solutions</Typography>
              <Typography variant="body2">Cutting-edge software designed to boost your productivity.</Typography>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Paper
              sx={{
                padding: 3,
                textAlign: 'center',
                transition: 'all 0.3s ease',
                '&:hover': {
                  transform: 'scale(1.05)',
                  boxShadow: '0 4px 15px rgba(0, 0, 0, 0.1)',
                },
              }}
            >
              <Typography variant="h6">Hardware Devices</Typography>
              <Typography variant="body2">Innovative hardware for seamless performance and reliability.</Typography>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Paper
              sx={{
                padding: 3,
                textAlign: 'center',
                transition: 'all 0.3s ease',
                '&:hover': {
                  transform: 'scale(1.05)',
                  boxShadow: '0 4px 15px rgba(0, 0, 0, 0.1)',
                },
              }}
            >
              <Typography variant="h6">Services</Typography>
              <Typography variant="body2">Expert advice and consulting services for your business needs.</Typography>
            </Paper>
          </Grid>
        </Grid>
      </Box>

      <Typography variant="h4" sx={{ fontWeight: '600', textAlign: 'center' }} gutterBottom>
        Featured products
      </Typography>
      <Grid container spacing={4}>
        {productList.map((product) => (
          <Grid item xs={12} sm={4} key={product.id}>
            <Paper
              sx={{
                padding: 3,
                textAlign: 'center',
                transition: 'all 0.3s ease',
                '&:hover': {
                  transform: 'scale(1.05)',
                  boxShadow: '0 4px 15px rgba(0, 0, 0, 0.1)',
                },
              }}
            >
              <img
                src={product.imgUrl}
                alt={product.name}
                style={{ width: '100%', height: 'auto' }}
              />
              <Typography variant="h6" sx={{ marginTop: 2 }}>
                {product.name}
              </Typography>
              <Typography variant="body2" sx={{ marginTop: 1 }}>
                {product.description}
              </Typography>
              <Typography variant="body1" sx={{ fontWeight: 'bold', marginTop: 2 }}>
                {product.price}
              </Typography>
              <Button
                variant="contained"
                onClick={goToContact} // Use goToContact function to navigate to the Contact Us page
                sx={{ backgroundColor: '#388E3C', color: 'fff', marginTop: 2 }}
              >
                Request More Info
              </Button>
            </Paper>
          </Grid>
        ))}
      </Grid>

      <Box sx={{ marginTop: 6, textAlign: 'center' }}>
        <Typography variant="h4" sx={{ fontWeight: '600', textAlign: 'center' }} gutterBottom>
          What our customers are saying
        </Typography>
        <Box sx={{ display: 'flex', justifyContent: 'center', gap: 3 }}>
          <Paper
            sx={{
              padding: 3,
              width: 300,
              transition: 'all 0.3s ease',
              '&:hover': {
                transform: 'scale(1.05)',
                boxShadow: '0 4px 15px rgba(0, 0, 0, 0.1)',
              },
            }}
          >
            <Typography variant="body1">
              "Product A has completely transformed my workflow. I can’t imagine going back to the old way of doing things."
            </Typography>
            <Typography variant="body2" sx={{ fontStyle: 'italic', marginTop: 1 }}>
              - John Doe, CEO of TechCorp
            </Typography>
          </Paper>
          <Paper
            sx={{
              padding: 3,
              width: 300,
              transition: 'all 0.3s ease',
              '&:hover': {
                transform: 'scale(1.05)',
                boxShadow: '0 4px 15px rgba(0, 0, 0, 0.1)',
              },
            }}
          >
            <Typography variant="body1">
              "Product B is exactly what our team needed to scale up operations. Highly recommend it!"
            </Typography>
            <Typography variant="body2" sx={{ fontStyle: 'italic', marginTop: 1 }}>
              - Jane Smith, Operations Manager at InnovateTech
            </Typography>
          </Paper>
        </Box>
      </Box>
    </Container>
  );
};

export default Products;
