import React, { useEffect, useState } from 'react';
import { Container, Grid, Paper, Typography, Button, Box, CircularProgress } from '@mui/material';
import { Link } from 'react-router-dom';
import axios from 'axios';

function Blogs() {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);

  const apiUrl = process.env.BASE_URL;

  useEffect(() => {
    window.scrollTo(0, 0);
    const fetchPosts = async () => {
      try {
        const response = await axios.get(`https://informative-servre.vercel.app/api/blogs`);
        setPosts(response.data);
      } catch (error) {
        console.error('Error fetching blog posts:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchPosts();
  }, []);

  return (
    <Container sx={{ paddingTop: 4 }}>
      <Typography variant="h4" sx={{ fontWeight: '600' }} gutterBottom align="center">
        Latest Blog Posts
      </Typography>

      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '60vh' }}>
          <CircularProgress size={80} sx={{ color: '#388E3C' }} />
        </Box>
      ) : (
        <Grid container spacing={4}>
          {posts.length === 0 ? (
            <Typography variant="h6" color="textSecondary" align="center" sx={{ width: '100%' }}>
              No blog posts available at the moment.
            </Typography>
          ) : (
            posts.map((post) => (
              <Grid item xs={12} sm={6} md={4} key={post._id} style={{ marginBottom: '5vh' }}>
                <Paper
                  sx={{
                    padding: 3,
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                    transition: 'all 0.3s ease',
                    '&:hover': {
                      transform: 'scale(1.05)',
                      boxShadow: '0 4px 15px rgba(0, 0, 0, 0.1)',
                    },
                  }}
                >
                  <Typography
                    variant="h5"
                    sx={{
                      fontWeight: 'bold',
                      color: '#333',
                      display: 'block', // Change this to 'block' to make sure it behaves as a block element
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap', // Prevents wrapping
                      maxWidth: '100%', // Ensures it uses full width available
                    }}
                  >
                    {post.title}
                  </Typography>

                  <Typography
                    variant="body2"
                    sx={{
                      marginTop: 1,
                      color: '#555',
                      display: '-webkit-box', // To make it behave like a block element and clamp the text
                      WebkitBoxOrient: 'vertical', // Needed for line clamping
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      WebkitLineClamp: 3, // Limiting to 3 lines (adjust as needed)
                    }}
                  >
                    {post.excerpt}
                  </Typography>

                  <Box sx={{ marginTop: 'auto' }}>
                    <Button
                      component={Link}
                      to={`/blog/${post._id}`}
                      variant="contained"
                      sx={{
                        marginTop: 2,
                        padding: '10px 20px',
                        backgroundColor: '#388E3C',
                        textDecoration: 'none',
                        '&:hover': {
                          backgroundColor: '#388E3C',
                        },
                      }}
                    >
                      Read More
                    </Button>
                  </Box>
                </Paper>
              </Grid>
            ))
          )}
        </Grid>
      )}
    </Container>
  );
}

export default Blogs;
