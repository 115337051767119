import React, { useEffect } from 'react';
import { Container, Paper, Typography, Box } from '@mui/material';

const TermsOfService = () => {
  useEffect(()=>{
    window.scrollTo(0, 0);
  },[])

  return (
    <Container maxWidth="lg" sx={{ marginTop: 6 }}>
      <Paper sx={{ padding: 4 }}>
        <Typography variant="h4" fontWeight={600} gutterBottom align="center">
          Terms of Service
        </Typography>
        <Typography variant="body2" gutterBottom align="center">
          Last updated: November 2024
        </Typography>

        <section>
          <Typography variant="h6" sx={{ fontWeight: 'bold', marginTop: 3 }}>
            Introduction
          </Typography>
          <Typography variant="body1" sx={{ marginTop: 2 }}>
            Welcome to TechInfoHub. These Terms of Service govern your use of our website and services. By accessing or using our services, you agree to be bound by these terms.
          </Typography>
        </section>

        <section>
          <Typography variant="h6" sx={{ fontWeight: 'bold', marginTop: 3 }}>
            Use of Services
          </Typography>
          <Typography variant="body1" sx={{ marginTop: 2 }}>
            You agree to use our services only for lawful purposes and in accordance with these Terms. You are responsible for ensuring that your use of our services does not infringe on any laws, regulations, or the rights of others.
          </Typography>
        </section>


        <section>
          <Typography variant="h6" sx={{ fontWeight: 'bold', marginTop: 3 }}>
            Intellectual Property
          </Typography>
          <Typography variant="body1" sx={{ marginTop: 2 }}>
            All content, software, and materials provided on our website are owned by TechInfoHub or its licensors and are protected by copyright and intellectual property laws. You are granted a limited, non-exclusive license to use the services for personal or internal business purposes.
          </Typography>
        </section>

        <section>
          <Typography variant="h6" sx={{ fontWeight: 'bold', marginTop: 3 }}>
            Limitation of Liability
          </Typography>
          <Typography variant="body1" sx={{ marginTop: 2 }}>
            In no event will TechInfoHub be liable for any indirect, incidental, special, or consequential damages arising from your use of our services. Our total liability, in any case, shall be limited to the amount you paid for the service.
          </Typography>
        </section>

        <section>
          <Typography variant="h6" sx={{ fontWeight: 'bold', marginTop: 3 }}>
            Termination
          </Typography>
          <Typography variant="body1" sx={{ marginTop: 2 }}>
            We reserve the right to suspend or terminate your access to our services if you violate these Terms of Service or engage in unlawful conduct.
          </Typography>
        </section>

        <section>
          <Typography variant="h6" sx={{ fontWeight: 'bold', marginTop: 3 }}>
            Changes to Terms
          </Typography>
          <Typography variant="body1" sx={{ marginTop: 2 }}>
            We may update these Terms of Service periodically. Any changes will be posted on this page, and the revised date will be updated. Please check this page regularly to stay informed.
          </Typography>
        </section>

        <section>
          <Typography variant="h6" sx={{ fontWeight: 'bold', marginTop: 3 }}>
            Contact Us
          </Typography>
          <Typography variant="body1" sx={{ marginTop: 2 }}>
            If you have any questions about these Terms of Service, please contact us at: <a href="mailto:support@techinfohub.online">TechInfoHub.com</a>
          </Typography>
        </section>
      </Paper>
    </Container>
  );
};

export default TermsOfService;
