import React, { useState } from 'react';
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Button,
  Drawer,
  List,
  ListItem,
  ListItemText,
  useTheme,
  useMediaQuery
} from '@mui/material';
import { Menu as MenuIcon, Close as CloseIcon } from '@mui/icons-material';
import { Link, useNavigate } from 'react-router-dom';
import logo from '../images/logo.png';

const Navbar = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm')); // Check if screen size is small

  const navigate = useNavigate();

  const navLinks = ['About Us', 'Services', 'Products', 'Blogs', 'Contact'];

  const handleLinkClick = (link) => {
    if (isMobile) {
      setDrawerOpen(false); // Close drawer on mobile after navigation
    }
    navigate(`/${link.replace(' ', '').toLowerCase()}`);
  };

  const loadHandler = () => {
    window.scrollTo(0, 0);
    navigate('/');
  };

  const mobileDrawer = (
    <Drawer
      anchor="left"
      open={drawerOpen}
      onClose={() => setDrawerOpen(false)}
      transitionDuration={300}
    >
      <List>
        <ListItem button onClick={() => setDrawerOpen(false)}>
          <ListItemText primary={<Typography variant="h6">Close</Typography>} />
          <IconButton>
            <CloseIcon />
          </IconButton>
        </ListItem>
        {navLinks.map((link) => (
          <ListItem button key={link} onClick={() => handleLinkClick(link)}>
            <ListItemText primary={<Typography variant="h6">{link}</Typography>} />
          </ListItem>
        ))}
      </List>
    </Drawer>
  );

  return (
    <>
      <AppBar position="sticky" sx={{ backgroundColor: '#388E3C', boxShadow: '0px 4px 12px rgba(0,0,0,0.1)' }}>
        <Toolbar>
          <Typography onClick={loadHandler} variant="h6" sx={{ flexGrow: 1, fontWeight: 700, cursor: 'pointer' }}>
            <img
              src={logo}
              alt="Logo"
              style={{
                height: isMobile ? '50px' : '70px', 
                width: 'auto',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginLeft: '3vw',  
                marginTop: '10px',   
                marginBottom: '10px' ,
                borderRadius : '12px'
              }}
            />
          </Typography>

          {!isMobile && (
            <>
              {navLinks.map((link) => (
                <Button
                  key={link}
                  color="inherit"
                  sx={{
                    marginLeft: 3,
                    transition: 'color 0.3s ease',
                    fontWeight: 600,
                    padding: '10px 15px', // Added padding to nav items
                  }}
                  onMouseEnter={(e) => (e.target.style.color = '#ff9800')}
                  onMouseLeave={(e) => (e.target.style.color = 'white')}
                  onClick={() => handleLinkClick(link)}
                >
                  {link}
                </Button>
              ))}
            </>
          )}

          {isMobile && (
            <IconButton edge="end" color="inherit" onClick={() => setDrawerOpen(true)} aria-label="menu">
              <MenuIcon />
            </IconButton>
          )}
        </Toolbar>
      </AppBar>

      {mobileDrawer}
    </>
  );
};

export default Navbar;
