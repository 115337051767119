import React from 'react';
import { Grid, Card, CardMedia } from '@mui/material';
import img1 from '../../images/ourWork-1.jpg'
import img2 from '../../images/ourWork-2.avif'
import img3 from '../../images/ourWork-3.jpg'
import img4 from '../../images/ourWork-4.jpg'
import img5 from '../../images/ourWork-5.avif'

function ImageGallery() {
  const images = [
      img1,img2,img3,img4,img5
 ];

  return (
    <Grid container spacing={4} justifyContent="center">
      {images.map((image, index) => (
        <Grid item xs={12} sm={6} md={4} key={index}>
          <Card sx={{ transition: 'all 0.3s ease', '&:hover': { transform: 'scale(1.0001)', boxShadow: '0 4px 15px rgba(0, 0, 0, 0.1)' } }}>
            <CardMedia component="img" image={image} alt={`Gallery Image ${index}`} />
          </Card>
        </Grid>
      ))}
    </Grid>
  );
}

export default ImageGallery;
