import React from 'react';
import { Typography, Button } from '@mui/material';
import { Link } from 'react-router-dom';
import hero from '../../images/hero-banner.avif'

function HeroSection() {
  return (
    <div
      style={{
        backgroundImage:
          `url(https://img.freepik.com/premium-photo/banner-with-workplace-sunlight-woman-prints-keyboard_1048944-26300047.jpg?w=1060)`, 
        backgroundSize: 'cover', 
        backgroundPosition: 'center',
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
        color: 'white',
        textAlign: 'center',
      }}
    >
      <Typography
        variant="h2"
        sx={{
          fontWeight: 'bold',
          color: '#fff',
          fontSize: { xs: '2rem', sm: '3rem', md: '4rem' }, 
          lineHeight: 1.2,
          letterSpacing: 1,
        }}
      >
        TechInfoHub
      </Typography>
      <Typography
        variant="h6"
        sx={{
          marginTop: 2,
          color: '#fff',
          fontSize: { xs: '1rem', sm: '1.25rem', md: '1.5rem' }, 
          lineHeight: 1.5,
          opacity: 0.9,
        }}
      >
        Delivering cutting-edge IT solutions for your business success
      </Typography>
      <Button
        variant="contained"
        sx={{
          backgroundColor: '#388E3C',
          marginTop: 3,
          padding: '10px 20px',
          fontSize: { xs: '0.9rem', sm: '1.1rem', md: '1.2rem' },
          fontWeight: '600',
          textTransform: 'none',
          '&:hover': {
            backgroundColor: '#00C853',
            transform: 'scale(1.05)',
          },
        }}
        component={Link}
        to="/aboutus"
      >
        Learn More About Us
      </Button>
    </div>
  );
}

export default HeroSection;
