import React, { useEffect, useState } from 'react';
import { Container, Typography, Grid, Paper, Box, Link, CircularProgress } from '@mui/material';
import axios from 'axios';
import { Link as RouterLink } from 'react-router-dom';

function Services() {
  const [services, setServices] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchServices = async () => {
      try {
        const response = await axios.get('https://informative-servre.vercel.app/api/services');
        setServices(response.data);
      } catch (error) {
        console.error('Error fetching services', error);
      } finally {
        setLoading(false);
      }
    };
    fetchServices();
  }, []);

  return (
    <Container maxWidth="lg" sx={{ marginTop: 6 }}>
      <Typography variant="h4" gutterBottom sx={{ textAlign: 'center', fontWeight: 'bold' }}>
        Our Services
      </Typography>

      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 4 }}>
          <CircularProgress size={80} sx={{ color: '#388E3C' }} />
        </Box>
      ) : (
        <Grid container spacing={3}>
          {services.length > 0 ? (
            services.map((service) => (
              <Grid item xs={12} sm={6} md={4} key={service._id}>
                <Paper
                  elevation={3}
                  sx={{
                    padding: '20px',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    height: '380px',
                    minHeight: 380,
                    transition: 'all 0.3s ease',
                    '&:hover': {
                      transform: 'scale(1.05)',
                      boxShadow: '0 4px 15px rgba(0, 0, 0, 0.1)',
                    },
                  }}
                >
                  <Typography
                    variant="h6"
                    sx={{
                      fontWeight: 'bold',
                      marginBottom: '15px',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      display: '-webkit-box',
                      WebkitLineClamp: 2,
                      WebkitBoxOrient: 'vertical',
                    }}
                  >
                    {service.name}
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{
                      lineHeight: '1.6',
                      color: '#555',
                      flex: 1,
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      display: '-webkit-box',
                      WebkitLineClamp: 3,
                      WebkitBoxOrient: 'vertical',
                    }}
                  >
                    {service.description}
                  </Typography>
                  <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '15px' }}>
                    <RouterLink to={`/services/${service._id}`} style={{ textDecoration: 'none' }}>
                      <Typography
                        sx={{
                          color: '#388E3C',
                          fontWeight: 'bold',
                          textDecoration: 'none',
                        }}
                      >
                        View Details
                      </Typography>
                    </RouterLink>
                  </Box>
                </Paper>
              </Grid>
            ))
          ) : (
            <Typography variant="h6" sx={{ textAlign: 'center', marginTop: 2 }}>
              No services available at the moment.
            </Typography>
          )}
        </Grid>
      )}
    </Container>
  );
}

export default Services;
