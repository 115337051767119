import React, { useEffect } from 'react';
import { Container, Paper, Typography, Box } from '@mui/material';

function About() {

  useEffect(()=>{
    window.scrollTo(0, 0);
  },[])

  return (
    <Container maxWidth="lg" sx={{ marginTop: 6 }}>
      <Typography variant="h4" gutterBottom align="center" sx={{ fontSize: '2rem', fontWeight: '600' }}>
        About Us
      </Typography>

      <Paper sx={{ padding: 4 }}>
        <Box mb={4}>
          <Typography variant="h6" sx={{ fontSize: '1.75rem', fontWeight: '500'}}>
            Company Name
          </Typography>
          <Typography variant="body1" sx={{ fontSize: '1rem', fontWeight: '400',  marginTop: 2 }}>
          TechInfoHub
          </Typography>
        </Box>

        <Box mb={4}>
          <Typography variant="h6" sx={{ fontSize: '1.75rem', fontWeight: '500', }}>
            Industry
          </Typography>
          <Typography variant="body1" sx={{ fontSize: '1rem', fontWeight: '400', marginTop: 2 }}>
          TechInfoHub
          </Typography>
        </Box>

        <Box mb={4}>
          <Typography variant="h6" sx={{ fontSize: '1.75rem', fontWeight: '500' }}>
            Mission Statement
          </Typography>
          <Typography variant="body1" sx={{ fontSize: '1rem', fontWeight: '400',  marginTop: 2 }}>
            Our mission is to empower businesses through innovative IT services. We strive to deliver excellence and exceed expectations with every project.
          </Typography>
        </Box>

        <Box mb={4}>
          <Typography variant="h6" sx={{ fontSize: '1.75rem', fontWeight: '500' }}>
            Vision Statement
          </Typography>
          <Typography variant="body1" sx={{ fontSize: '1rem', fontWeight: '400',  marginTop: 2 }}>
            To be the leading provider of cutting-edge IT solutions, driving digital transformation for businesses globally.
          </Typography>
        </Box>

        <Box mb={4}>
          <Typography variant="h6" sx={{ fontSize: '1.75rem', fontWeight: '500',}}>
            Key Services/Products
          </Typography>
          <Typography variant="body1" sx={{ fontSize: '1rem', fontWeight: '400',  marginTop: 2 }}>
            • Custom Software Development <br />
            • Cloud Solutions <br />
            • IT Consulting <br />
            • Web and Mobile App Development <br />
            • Data Analytics & AI Solutions
          </Typography>
        </Box>

        <Box mb={4}>
          <Typography variant="h6" sx={{ fontSize: '1.75rem', fontWeight: '500'}}>
            Target Audience
          </Typography>
          <Typography variant="body1" sx={{ fontSize: '1rem', fontWeight: '400',  marginTop: 2 }}>
            Our primary audience includes SMEs, large enterprises, and startups in the tech, healthcare, and finance sectors looking for tailored IT solutions to boost their growth and innovation.
          </Typography>
        </Box>
      </Paper>
    </Container>
  );
}

export default About;
