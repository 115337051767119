import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import About from './pages/About';
import Services from './pages/Services';
import Contact from './pages/Contact';
import Navbar from './components/Navbar';
import Products from './pages/Products';
import BlogDetail from './pages/BlogDetail';
import Blogs from './pages/Blog';
import NotFound from './pages/NotFound'; 
import PrivacyPolicy from './pages/PrivacyPolicy';  
import TermsOfService from './pages/TermsOfService';
import CookiePolicy from './pages/CookiePolicy';
import Footer from './pages/Footer';
import ServiceDetail from './pages/ServiceDetail';

const Layout = ({ children }) => (
  <>
    <Navbar />
    {children}
    <Footer/>
  </>
);

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Layout><Home /></Layout>} />

        <Route path="/aboutus" element={<Layout><About /></Layout>} />

        <Route path="/services" element={<Layout><Services /></Layout>} />

        <Route path="/services/:id" element={<ServiceDetail />} />

        <Route path="/products" element={<Layout><Products /></Layout>} />

        <Route path="/blogs" element={<Layout><Blogs /></Layout>} />

        <Route path="/blog/:id" element={<BlogDetail />} />

        <Route path="/contact" element={<Layout><Contact /></Layout>} />

        <Route path="/privacy-policy" element={<Layout><PrivacyPolicy /></Layout>} />
        <Route path="/terms-of-service" element={<Layout><TermsOfService /></Layout>} />
        <Route path="/cookie-policy" element={<Layout><CookiePolicy /></Layout>} />

        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
}

export default App;
