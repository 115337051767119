import React, { useEffect, useState } from 'react';
import { Grid, Paper, Typography, Button, TextField, Container } from '@mui/material';
import { toast, Toaster } from 'react-hot-toast';

function Contact() {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  const emailComRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.com$/;
  const nameRegex = /^[A-Za-z\s]+$/;

  const validateForm = () => {
    if (!name.trim()) {
      toast.error('Name is required!');
      return false;
    }
    if (!nameRegex.test(name)) {
      toast.error('Name can only contain letters and spaces!');
      return false;
    }
    if (!email.trim()) {
      toast.error('Email is required!');
      return false;
    }
    if (!emailRegex.test(email)) {
      toast.error('Please enter a valid email!');
      return false;
    }
    if (!emailComRegex.test(email)) {
      toast.error('Email must end with .com!');
      return false;
    }
    if (!message.trim()) {
      toast.error('Message is required!');
      return false;
    }
    if (message.length > 200) {
      toast.error('Message cannot exceed 200 characters!');
      return false;
    }
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    const formData = { name, email, message };
    setLoading(true);

    try {
      const response = await fetch('https://informative-servre.vercel.app/api/contact', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        toast.success('Your message has been sent successfully!');
        setName('');
        setEmail('');
        setMessage('');
      } else {
        throw new Error('Failed to send message');
      }
    } catch (error) {
      toast.error('Error sending message. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container sx={{ marginTop: 6 }}>
      <Typography variant="h4" gutterBottom align="center" sx={{ fontSize: '2rem', fontWeight: '600' }}>
        Contact Us
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Paper sx={{ padding: 3, height: '100%' }}>
            <Typography variant="h6" sx={{ fontSize: '1.75rem', fontWeight: '600', textAlign: 'center' }}>
              Get in Touch
            </Typography>
            <Typography variant="body1" sx={{ fontSize: '1rem', fontWeight: '400', textAlign: 'center', marginTop: 2 }}>
              We are always happy to hear from you. Whether you have a question, feedback, or just want to say hello,
              feel free to reach out! Our team will get back to you as soon as possible. We value communication and
              aim to provide you with the best experience possible.
            </Typography>
            <Typography variant="body2" sx={{ fontSize: '1rem', fontWeight: '400', textAlign: 'center', marginTop: 2 }}>
              You can contact us directly via email at{' '}
              <strong><a href="mailto:support@techinfohub.online">TechInfoHub</a></strong> or through the form on
              the right. We look forward to connecting with you!
            </Typography>
            <Typography variant="body2" sx={{ fontSize: '1rem', fontWeight: '400', textAlign: 'center', marginTop: 2 }}>
              Our office is open Monday to Friday, 9 AM to 6 PM (GMT). If you're nearby, feel free to visit us!
            </Typography>
          </Paper>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Paper sx={{ padding: 3, height: '100%' }}>
            <Typography variant="h6" sx={{ fontSize: '1.75rem', fontWeight: '600', textAlign: 'center' }}>Contact Form</Typography>
            <form onSubmit={handleSubmit}>
              <TextField
                fullWidth
                label="Your Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
                sx={{ marginBottom: 2 }}
              />
              <TextField
                fullWidth
                label="Your Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                type="email"
                required
                sx={{ marginBottom: 2 }}
              />
              <TextField
                fullWidth
                label="Your Message"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                multiline
                rows={4}
                required
                sx={{ marginBottom: 2 }}
              />
              <Button type="submit" variant="contained" color="primary" sx={{ backgroundColor: '#388E3C', color: 'fff' }} disabled={loading}>
                {loading ? 'Sending...' : 'Send Message'}
              </Button>
            </form>
            
          </Paper>
        </Grid>
      </Grid>

      <Toaster position="top-center" reverseOrder={false} />
    </Container>
  );
}

export default Contact;
