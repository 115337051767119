import React, { useState } from 'react';
import { IconButton, Tooltip } from '@mui/material';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { motion } from 'framer-motion'; 

const WhatsAppChatBot = () => {
  const [showTooltip, setShowTooltip] = useState(false);

  const handleMouseEnter = () => setShowTooltip(true);
  const handleMouseLeave = () => setShowTooltip(false);

  return (
    <div
      style={{
        position: 'fixed',
        bottom: '30px',
        right: '30px',
        zIndex: 1000,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Tooltip
        title="Chat with us on WhatsApp!"
        open={showTooltip}
        arrow
        placement="left"
        PopperProps={{
          disableInteractive: true,
        }}
      >
        <motion.div
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.3 }}
        >
          <IconButton
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            href="https://wa.me/9876543210"
            target="_blank"
            style={{
              backgroundColor: '#25d366',
              borderRadius: '50%',
              boxShadow: '0 4px 8px rgba(0,0,0,0.2)',
            }}
          >
            <WhatsAppIcon style={{ color: '#fff', fontSize: '40px' }} />
          </IconButton>
        </motion.div>
      </Tooltip>
    </div>
  );
};

export default WhatsAppChatBot;
